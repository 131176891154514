import html from './html';
import { useLocale } from './locale';

import './alert.css';

export default ({children}) => {
	return html`
		<div role="alert" class=${children ? '' : 'inactive'}>
			${children}
		</div>
	`;
};

export const AlertError = ({error}) => {
	const localize = useLocale();
	let text;

	if (error.code === 'OVERCONSTRAINED') {
		text = localize('ERROR_OVERCONSTRAINED');
	} else if (error.code === 'UNDERCONSTRAINED') {
		text = localize('ERROR_UNDERCONSTRAINED');
	} else if (error.code === 'NEGATIVETERM') {
		text = localize('ERROR_NEGATIVE_TERM');
	} else {
		text = error.message;
	}

	return html`<p>${text}</p>`;
};

export const AlertExample = ({name, onShowNext}) => {
	const localize = useLocale();
	return html`
		<p>
			${localize('EXAMPLE_TITLE')} ${name} ${localize('EXAMPLE_GUIDANCE')}
		</p>
		<div class="actions">
			<button onClick=${onShowNext}>${localize('EXAMPLE_NEXT')}</button>
		</div>
	`;
};
