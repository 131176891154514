import { useCallback, useState } from 'preact/hooks';
import solve from './solve';

const noMeasures = Object.freeze({
	calorie: null,
	carbohydrate: null,
	cholesterol: null,
	totalFat: null,
	fiber: null,
	protein: null,
	saturatedFat: null,
	sodium: null,
	sugar: null,
	transFat: null,
});

const buildMatrix = (measures, ingredients) => {
	return Object.keys(measures)
		.filter((name) => measures[name] !== null)
		.map((name) => [
			...ingredients.map(({food}) => food[name]),
			measures[name]
		]);
};

class NegativeTermError extends Error {
	constructor() {
		super();
		this.code = 'NEGATIVETERM';
	}
}

const hasNegative = (solution) => solution.some((term) => term < 0);

export default () => {
	const [ingredients, setIngredients] = useState(Object.freeze([]));
	const [measures, setMeasures] = useState(noMeasures);
	const [exampleName, setExampleName] = useState(null);
	const [error, setError] = useState(null);

	const setExample = useCallback(({name, measures, foods}) => {
			measures = Object.assign({}, noMeasures, measures);
			const newIngredients = foods.map((food) => ({food, amount: null}));
			const matrix = buildMatrix(measures, newIngredients);
			let solution;
			if (matrix.length) {
				try {
					solution = solve(matrix);
				} catch (caught) {
					setError(caught);
				}
			}

			if (solution) {
				if (hasNegative(solution)) {
					setError(new NegativeTermError());
				} else {
					setError(null);
				}
			}

			setIngredients(newIngredients.map(({food}, index) => ({
				food,
				amount: solution ? 100 * solution[index] : null
			})));

			setMeasures(measures);
			setExampleName(name);
		},
		[setMeasures, setError, setIngredients]
	);
	const setMeasure = useCallback(({name, value}) => {
			if (measures[name] === value) {
				return;
			}
			const newMeasures = Object.freeze({ ...measures, [name]: value });

			const matrix = buildMatrix(newMeasures, ingredients);
			let solution;
			if (matrix.length) {
				try {
					solution = solve(matrix);
				} catch (caught) {
					setError(caught);
				}
			}

			if (solution) {
				if (hasNegative(solution)) {
					setError(new NegativeTermError());
				} else {
					setError(null);
				}
			}

			setIngredients(ingredients.map(({food}, index) => ({
				food,
				amount: solution ? 100 * solution[index] : null
			})));

			setMeasures(newMeasures);
			setExampleName(null);
		},
		[measures, setMeasures, setError, ingredients, setIngredients]
	);

	const addFood = useCallback((food) => {
			const hasIngredient = ingredients.find(({food: {id}}) => {
				return id === food.id;
			});
			if (hasIngredient) {
				return;
			}
			const newIngredients = ingredients.concat({
				food,
				amount: null
			});

			const matrix = buildMatrix(measures, newIngredients);
			let solution;
			if (matrix.length) {
				try {
					solution = solve(matrix);
				} catch (caught) {
					setError(caught);
				}
			}

			if (solution) {
				if (hasNegative(solution)) {
					setError(new NegativeTermError());
				} else {
					setError(null);
				}
			}

			setIngredients(newIngredients.map(({food}, index) => ({
				food,
				amount: solution ? 100 * solution[index] : null
			})));
			setExampleName(null);
		},
		[measures, ingredients, setIngredients]
	);

	const removeFood = useCallback((food) => {
			const newIngredients = ingredients.filter(({food: {id}}) => {
				return id !== food.id;
			});

			if (newIngredients.length === ingredients.length) {
				return;
			}

			const matrix = buildMatrix(measures, newIngredients);
			let solution;
			if (matrix.length) {
				try {
					solution = solve(matrix);
				} catch (caught) {
					setError(caught);
				}
			}

			if (solution) {
				if (hasNegative(solution)) {
					setError(new NegativeTermError());
				} else {
					setError(null);
				}
			}

			setIngredients(newIngredients.map(({food}, index) => ({
				food,
				amount: solution ? 100 * solution[index] : null
			})));
			setExampleName(null);
		},
		[measures, ingredients, setIngredients]
	);

	return {
		ingredients,
		exampleName,
		error,
		measures,
		setExample,
		setMeasure,
		addFood,
		removeFood
	};
};
