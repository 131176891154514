/**
 * An implementation of the "modal" design pattern from the WAI-ARIA Authoring
 * Practices guideline.
 *
 * https://www.w3.org/TR/wai-aria-practices-1.1
 */
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

import html from './html';
import keyCodes from './key-codes';
import { useLocale } from './locale';

import './modal.css';

let count = 0;

const attemptFocus = (element) => {
	try {
		element.focus();
	// Work around bug in JSHint
	// https://github.com/jshint/jshint/issues/3480
	// jshint -W137
	} catch ({}) {}
	// jshint +W137

	return document.activeElement === element;
};

const focusLastDescendent = (element) => {
	for (let index = element.childNodes.length - 1; index >= 0; index -= 1) {
		const child = element.childNodes[index];

		if (attemptFocus(child) || focusLastDescendent(child)) {
			return true;
		}
	}

	return false;
};

export default ({onClose, title, isActive, children}) => {
	const [id] = useState(() => `modal-${count += 1}`);
	const originalFocus = useRef(document.activeElement);
	const localize = useLocale();
	const modalRef = useRef();
	const previousFocus = useRef();
	const handleClose = useCallback(
		() => {
			onClose();
		},
		[onClose]
	);

	useEffect(() => {
		if (!isActive) {
			originalFocus.current.focus();
			return;
		}
		originalFocus.current = document.activeElement;
		const button = modalRef.current.querySelector('header button');
		button.focus();
		previousFocus.current = button;
	}, [isActive]);

	useEffect(() => {
		if (!isActive) {
			return;
		}

		const trapFocus = (event) => {
			if (!modalRef.current.classList.contains('active')) {
				return;
			}
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				const closeButton =
					modalRef.current.querySelector('header button');

				if (previousFocus.current === closeButton) {
					focusLastDescendent(modalRef.current);
				} else {
					closeButton.focus();
				}
			}
			previousFocus.current = document.activeElement;
		};

		document.addEventListener('focus', trapFocus, true);

		return () => document.removeEventListener('focus', trapFocus, true);
	}, [isActive]);

	useEffect(() => {
		const onKeydown = (event) => {
			if (event.keyCode !== keyCodes.escape) {
				return;
			}
			handleClose();
		};
		document.addEventListener('keydown', onKeydown);

		return () => document.removeEventListener('keydown', onKeydown);
	}, [handleClose]);

	return html`
		<div ref=${modalRef} class="modal ${isActive ? 'active' : ''}">
			<div class="mask" onClick=${handleClose}></div>
			<div role="dialog" aria-modal="true" aria-labelledby=${id}>
				<header class="clearfix">
					<button
						aria-label=${localize('CLOSE')}
						onClick=${handleClose}>
						${'\u00d7'}
					</button>
					<h1 id=${id}>${title}</h1>
				</head>
				<div class="content">
					${children}
				</div>
			</div>
		</div>
	`;
};
