import { useState } from 'preact/hooks';

import html from './html';
import Modal from './modal';
import Nutrition from './nutrition';
import TutorialInstruction from './tutorial-instruction';
import { useLocale } from './locale';

import './ingredient-list.css';

export default function IngredientList({ingredients, onRemove, onCloseTutorial}) {
	const [detailFood, setDetailFood] = useState(null);
	const items = ingredients.map((ingredient) =>
		html`<${IngredientItem}
			ingredient=${ingredient}
			onRemove=${onRemove}
			onRequestDetail=${setDetailFood} />`
	);

	return html`
		<div class="ingredient-list-container">
			<${Modal}
				title=${detailFood && detailFood.name}
				isActive=${!!detailFood}
				onClose=${setDetailFood}>
				<${Nutrition} measures=${detailFood || {}} />
			</>

			<table class="ingredient-list">${items}</table>

			<${TutorialInstruction} step="3" onClose=${onCloseTutorial} />
		</div>
	`;
}

const IngredientItem = ({ingredient, onRemove, onRequestDetail}) => {
	const localize = useLocale();
	const amount = ingredient.amount === null ?
		'?' : Math.round(ingredient.amount);

	return html`<tr class="ingredient-list-item">
		<td class="amount">${amount}g</td>
		<td>
			<span class="food-name">${ingredient.food.name}</span>

			<button class="remove" onClick=${() => onRemove(ingredient.food)}>
				${localize('REMOVE')}
			</button>

			<button
				class="nutrition-facts"
				onClick=${() => onRequestDetail(ingredient.food)}>
				${localize('NUTRITION_FACTS')}
			</button>
		</td>
	</tr>`;
};
