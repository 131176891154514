import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { render } from 'preact';
import { useEffect, useCallback, useMemo, useState } from 'preact/hooks';
import html from './html';

import Alert, {AlertError, AlertExample} from './alert';
import DB from './db';
import Equations from './equations';
import Footer from './footer';
import Header from './header';
import HelpModal from './help-modal';
import IngredientList from './ingredient-list';
import LicensesModal from './licenses-modal';
import { LocaleContext } from './locale';
import Nutrition from './nutrition';
import Search from './search';
import examples from './examples';
import useSolution from './use-solution';
import useTutorial from './use-tutorial';

import './index.css';

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
	navigator.serviceWorker.register('service-worker.js', { scope: './' });
}

const App = () => {
	const [db] = useState(() => new DB());
	const [isLoaded, setIsLoaded] = useState(false);
	const [showingHelp, setShowingHelp] = useState(false);
	const [showingLicenses, setShowingLicenses] = useState(false);
	const [showingTutorial, setShowingTutorial] = useTutorial();
	const [locale, setLocale] = useState('en-us');
	const [dbError, setDbError] = useState(null);
	const {
		measures, ingredients, error, exampleName, setExample, setMeasure,
		addFood, removeFood
	} = useSolution();
	const demonstrate = useCallback(
		async () => {
			const index = examples.findIndex(({name}) => name === exampleName);
			const nextExample = examples[(index + 1) % examples.length];

			const reqs = nextExample.ingredients
				.map((ingredient) => db.find(ingredient));
			const foods = (await Promise.all(reqs))
				.map((results) => results[0]);
			setExample({
				name: nextExample.name,
				measures: nextExample.measures,
				foods
			});
		},
		[db, isLoaded, exampleName, setExample]
	);

	let alertContent = null;
	if (error || dbError) {
		alertContent = html`<${AlertError} error=${error || dbError} />`;
	} else if (exampleName) {
		alertContent = html`
			<${AlertExample} name=${exampleName} onShowNext=${demonstrate} />
		`;
	}

	useEffect(() => {
		if (isLoaded) {
			return;
		}
		const onError = (err) => setDbError(new Error(
			`Unable to open database: "${err.message}"`
		));
		db.open().then(() => setIsLoaded(true), onError);
	}, [db, setDbError, isLoaded]);

	return html`
		<main class="${showingTutorial ? 'tutorial-active' : ''}">
			<${LocaleContext.Provider} value=${locale}>

			<${Header}
				locale=${locale}
				setLocale=${setLocale}
				onDemonstrate=${demonstrate}
				onShowHelp=${() => setShowingHelp(true)} />

			<${Alert}>${alertContent}</>

			<${HelpModal}
				isActive=${showingHelp}
				onClose=${() => setShowingHelp(false)}
				showingTutorial=${showingTutorial}
				onShowTutorial=${() => setShowingTutorial(true)} />

			<${LicensesModal}
				isActive=${showingLicenses}
				onClose=${() => setShowingLicenses(false)} />

			<div class="content clearfix">
				<${Nutrition}
					class="nutrition"
					measures=${measures}
					onInput=${setMeasure}
					onCloseTutorial=${() => setShowingTutorial(false)} />

				<div class="food">
					<${Search}
						class="search"
						isLoaded=${isLoaded}
						db=${db}
						selected=${ingredients.map(({food}) => food)}
						onSelect=${addFood}
						onDeselect=${removeFood}
						onCloseTutorial=${() => setShowingTutorial(false)} />

					<${IngredientList}
						class="ingredientlist"
						ingredients=${ingredients}
						onRemove=${removeFood}
						onCloseTutorial=${() => setShowingTutorial(false)} />

					<${Equations}
						ingredients=${ingredients}
						measures=${measures} />
				</div>
			</div>

			<${Footer} onShowLicenses=${() => setShowingLicenses(true)} />
			</>
		</main>
	`;
};

render(html`<${App} />`, document.body);
