import html from './html';
import { useLocale } from './locale';

import './tutorial-instruction.css';

export default ({step, onClose}) => {
	const localize = useLocale();
	const i18nName = `TUTORIAL_STEP_${step}`;
	// This component may be rendered within an HTML form, where the default
	// action would trigger an undesired navigation. Prevent this by wrapping
	// the event handler.
	const handleClose = (event) => {
		event.preventDefault();
		onClose();
	};

	return html`
		<div class="tutorial-instruction tutorial-step-${step}">

			<button
				aria-label=${localize('CLOSE')}
				onClick=${handleClose}>
				${'\u00d7'}
			</button>

			<h1>${localize('TUTORIAL_STEP')} ${step}</h1>

			<p>${localize(i18nName)}</p>
		</div>
	`;
};
