import { useState } from 'preact/hooks';

import html from './html';

import './locale-menu.css';

let count = 0;

export default ({value, onChange}) => {
	const [domId] = useState(() => `locale-menu-${count += 1}`);
	const handleInput = (event) => {
		onChange(event.target.value);
	};

	return html`
		<div class="locale-menu">
			Locale:
			<input
				type="radio"
				name=${domId}
				onInput=${handleInput}
				id=${domId}-en-us
				checked=${value === 'en-us' ? 'checked' : ''}
				value="en-us" />
			<label for=${domId}-en-us >English</label>

			<input
				type="radio"
				name=${domId}
				onInput=${handleInput}
				id=${domId}-en-pirate
				checked=${value === 'en-pirate' ? 'checked' : ''}
				value="en-pirate" />
			<label for=${domId}-en-pirate>Pirate</label>
		</div>
	`;
};
