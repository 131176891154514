import html from './html';
import Modal from './modal';
import { useLocale } from './locale';

import projects from './dummy.licenses';
import license from '../LICENSE.txt';

import './licenses-modal.css';

const LicensesView = () => {
	const localize = useLocale();

	const info = projects.map((project) => html`
		<div class="external-project">
			<h3>${project.name} (${localize('VERSION')} ${project.version})</h3>

			<a href=${project.repository}>
				${project.repository}
			</a>

			<details>
				<summary>${project.license.name} license</summary>

				<pre class="license">${project.license.text}</pre>
			</details>
		</div>
	`);

	return html`
		<p>
			${html`${localize('INGRADIENT_FREE_SOFTWARE_LONG')}`}
		</p>

		<details>
			<summary>View license</summary>

			<pre class="license">${license}</pre>
		</details>

		<p>
			Ingradient includes code from ${projects.length} free software
			projects, as listed below:
		</p>

		${info}
	`;
};

export default ({isActive, onClose}) => {
	const localize = useLocale();

	return html`
		<${Modal}
			title=${localize('LICENSES')}
			isActive=${isActive}
			onClose=${onClose}>
			<${LicensesView} />
		</>
	`;
};
