export default [
	{
		name: 'Nature Valley Peanut Butter Granola Bars',
		ingredients: [
			'cereals oats regular and quick not fortified dry',
			'sugars granulated',
			'canola oil',
			'peanut butter smooth without salt'
		],
		measures: {
			calorie: 190,
			totalFat: 8,
			fiber: 2,
			protein: 4
		}
	},
	{
		name: 'Boca Original Chik\'n Veggie Patties',
		ingredients: [
			'soy protein isolate',
			'wheat flour',
			'corn oil'
		],
		measures: {
			calorie: 130,
			totalFat: 4,
			protein: 11
		}
	},
	{
		name: 'Lawry\'s Chipotle Molasses Marinade',
		ingredients: [
			'vinegar distilled',
			'tomato paste',
			'molasses'
		],
		measures: {
			calorie: 30,
			carbohydrate: 7,
			sugar: 6
		}
	}
];
