import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

export const LocaleContext = createContext('en-us');

export const useLocale = () => {
	const locale = useContext(LocaleContext);
	return (code) => localize(locale, code);
};

import enUs from './locales/en-us.yml';
import enPirate from './locales/en-pirate.yml';


const locales = {
	'en-us': enUs,
	'en-pirate': enPirate,
};

const localize = (locale, code) => {
	return locales[locale][code];
};
