import {openDB} from 'idb';

// Work around bug in JSHint
// https://github.com/jshint/jshint/issues/3479
// jshint -W079
const name = 'outgradient';
// jshint +W079
const version = 1;
const storeName = 'ingredients';

export default class DB {
	constructor() {
		this._db = null;
	}

	async open() {
		this._db = await openDB(name, 1, {
			upgrade(db) {
				if (!db.objectStoreNames.contains(storeName)) {
					const nutrients = db.createObjectStore(
						storeName, { keyPath: 'id' }
					);
					nutrients.createIndex(
						'words', 'words', {multiEntry: true}
					);
				}
			}
		});

		if (true || await this._db.count(storeName) === 0) {
			const data = await (await fetch('./out.json')).json();
			const tx = this._db.transaction(storeName, 'readwrite');
			const store = tx.objectStore(storeName);

			await Promise.all(data.map((row) => store.put({
				id: row[0],
				name: row[1],
				calorie: row[2],
				totalFat: row[3],
				saturatedFat: row[4],
				monounsaturatedFat: row[5],
				polyunsaturatedFat: row[6],
				cholesterol: row[7],
				sodium: row[8],
				carbohydrate: row[9],
				fiber: row[10],
				sugar: row[11],
				protein: row[12],
				words: row[1].toLowerCase().replace(/[^a-z]+/g, ' ').split(/\s+/)
			})));

			await tx.done;
		}

		return this;
	}

	/**
	 * Query the database for one or more search terms separated by white space.
	 *
	 * This method uses the first term as the search index. It refines the
	 * results by filtering with any remaining terms.
	 *
	 * @param {string} terms - one or more search terms separated by white
	 *                         space
	 */
	async find(terms) {
		if (!this._db) {
			throw new Error('`find` invoked on database prior to loading.');
		}
		const results = [];
		const [first, ...otherTerms] = terms.trim().split(/\s+/);
		const otherPatterns = otherTerms
			.map((otherTerm) => new RegExp('^' + otherTerm, 'i'));
		let cursor = await this._db
			.transaction(storeName)
			.objectStore(storeName)
			.index('words')
			.openCursor(IDBKeyRange.bound(first, `${first}\u007f`));

		while (cursor) {
			// jshint loopfunc: true
			const matchesEveryTerm = !otherPatterns.some((other) => {
				return cursor.value.words.every((word) => !other.test(word));
			});
			// jshint loopfunc: false

			if (matchesEveryTerm) {
				results.push(cursor.value);
			}

			cursor = await cursor.continue();
		}

		// Shorter names are more likely to be relevant, so prioritize
		// according to length.
		results.sort((a, b) => a.name.length > b.name.length ? 1 : -1);

		return results;
	}
}

