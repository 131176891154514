import html from './html';
import { useState, useCallback } from 'preact/hooks';
import { useLocale } from './locale';
import TutorialInstruction from './tutorial-instruction';

import './nutrition.css';

// Source:
// https://www.fda.gov/food/new-nutrition-facts-label/daily-value-new-nutrition-and-supplement-facts-labels
const recommended = {
	calorie: 2000,
	totalFat: 78,
	saturatedFat: 20,
	monounsaturatedFat: null,
	polyunsaturatedFat: null,
	cholesterol: 300,
	sodium: 2300,
	carbohydrate: 267,
	fiber: 28,
	sugar: null,
	protein: 50,
};

let count = 0;

const Nutrient = ({id, name, title, onInput, measures}) => {
	const domId = `nutrient-${id}-${name}`;
	const scale = (name === 'sodium' || name === 'cholesterol') ? 1000 : 1;
	const unit = scale === 1 ? 'g' : 'mg';
	const handleChange = useCallback(
		(event) => {
			const value = event.target.value;
			const parsed = value.length ? parseFloat(value) / scale : null;
			onInput({ name, value: parsed });
		},
		[scale, name, onInput]
	);
	const displayValue = measures[name] === null ? '' : measures[name];

	return html`
		<label for=${domId}>${title}</label>
		<input
			class="tutorial-input tutorial-step-1"
			id=${domId}
			type="number"
			placeholder="?"
			value=${displayValue}
			onInput=${handleChange} />${unit}
	`;
};

const CalorieRow = ({id, measures, onInput}) => {
	const localize = useLocale();
	const domId = `nutrient-${id}-calorie`;
	const handleChange = useCallback(
		(event) => {
			const value = event.target.value;
			const parsed = value.length ? parseFloat(value) : null;
			onInput({ name: 'calorie', value: parsed });
		},
		[onInput]
	);
	const displayValue = measures.calorie === null ? '' : measures.calorie;

	return html`
		<tr class="primary calories">
			<td>
				<label for=${domId}>
					${localize('CALORIES')}
				</label>
			</td>
			<td>
				<input
					class="tutorial-input tutorial-step-1"
					id=${domId}
					type="number"
					onInput=${handleChange}
					value=${displayValue}
					onInput=${handleChange} />
			</td>
		</tr>
	`;
};

const percent = (measures, name) =>
	Math.round(100 * measures[name] / recommended[name]) + '%';

export default ({measures, onInput, onCloseTutorial}) => {
	const [id] = useState(() => ++count);
	const localize = useLocale();

	return html`
		<form class="nutrition">
			<${TutorialInstruction} step="1" onClose=${onCloseTutorial} />

			<fieldset disabled=${onInput ? '' : 'disabled'}>
				<legend>${localize('NUTRITION_FACTS')}</legend>

				<table>
					<thead class="amount">
						<tr>
							<td colspan="2">
								${localize('AMT_PER_SERVING')}
							</td>
						</tr>
					</thead>

					<tbody>
						<${CalorieRow}
							id=${id}
							measures=${measures}
							onInput=${onInput} />
					</tbody>

					<thead class="percent-dv">
						<tr>
							<td colspan="2">
								${localize('PCT_DAILY_VALUE')}*
							</td>
						</tr>
					</thead>

					<tbody>
						<tr class="primary">
							<td>
								<${Nutrient}
									name="totalFat"
									id=${id}
									title=${localize('TOTAL_FAT')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'totalFat')}</td>
						</tr>
						<tr>
							<td>
								<${Nutrient}
									name="saturatedFat"
									id=${id}
									title=${localize('SATURATED_FAT')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'saturatedFat')}</td>
						</tr>
						<tr>
							<td>
								<${Nutrient}
									name="transFat"
									id=${id}
									title=${localize('TRANS_FAT')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv"></td>
						</tr>
						<tr class="primary">
							<td>
								<${Nutrient}
									name="cholesterol"
									id=${id}
									title="${localize('CHOLESTEROL')}"
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'cholesterol')}</td>
						</tr>
						<tr class="primary">
							<td>
								<${Nutrient}
									name="sodium"
									id=${id}
									title=${localize('SODIUM')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'sodium')}</td>
						</tr>
						<tr class="primary">
							<td>
								<${Nutrient}
									name="carbohydrate"
									id=${id}
									title=${localize('TOTAL_CARBOHYDRATE')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'carbohydrate')}</td>
						</tr>
						<tr>
							<td>
								<${Nutrient}
									name="fiber"
									id=${id}
									title=${localize('FIBER')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv">${percent(measures, 'fiber')}</td>
						</tr>
						<tr>
							<td>
								<${Nutrient}
									name="sugar"
									id=${id}
									title=${localize('TOTAL_SUGARS')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv"></td>
						</tr>
						<tr class="primary">
							<td>
								<${Nutrient}
									name="protein"
									id=${id}
									title=${localize('PROTEIN')}
									onInput=${onInput}
									measures=${measures} />
							</td>
							<td class="pct-dv"></td>
						</tr>
					</tbody>
				</table>

				<div class="percent-dv-explanation">
					* ${localize('PCT_DAILY_VALUE_EXPLAINED')}
				</div>

				</fieldset>
		</form>
	`;
};
