import html from './html';

import LocaleMenu from './locale-menu';
import { useLocale } from './locale';

import './header.css';

export default ({locale, setLocale, onDemonstrate, onShowHelp}) => {
	const localize = useLocale();

	return html`
		<header>
			<h1>Ingradient</h1>
			<h2>${localize('DESCRIPTION')}</h2>
			<menu>
				<${LocaleMenu} value=${locale} onChange=${setLocale} />

				<button onClick=${onDemonstrate}>
					${localize('SHOW_ME_HOW')}
				</button>

				<button class="help" onClick=${onShowHelp}>
					${localize('HELP')}
				</button>
			</menu>
		</header>
	`;
};
