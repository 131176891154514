import html from './html';
import Modal from './modal';
import { useLocale } from './locale';

const emDash = String.fromCharCode(0x2014);

const Help = ({showingTutorial, onShowTutorial}) => {
	const tutorialControl = showingTutorial ?
		'Some hints labeled "Step" are overlaid on the application.' :
		html`
			<button onClick=${onShowTutorial}>
				Click here to enable the tutorial
			</button>
		`;

	return html`
		<h3>What is this thing?</h3>

		<p>
			Ingradient is an application designed to help you estimate the
			portions of ingredients in food products. In many countries, food
			manufacturers are required to provide nutrition data about their
			products. In the United States, this includes measurements for a
			handful of nutrients and a list of ingredients. By combining this
			information (which you provide) with nutrition data about the
			ingredients themselves (which <a
			href="https://www.ars.usda.gov/">the USDA provides</a>), this
			application makes an informed guess about how the amount of each
			ingredient in a given product.
		</p>

		<h3>How do I use it?</h3>

		<p>
			First, input some nutrition data as it appears on the product you'd
			like to learn about. Then, input one ingredient for each piece of
			nutrition data you've provided. The application should respond by
			displaying measurements for each of the ingredients you've entered.
			If you combine those ingredients in those amounts, then you'll wind
			up with a dish that has the same nutrition profile as your product.
		</p>

		<p>
			Whether it tastes any good is another story.
		</p>

		<p>${tutorialControl}</p>

		<h3>How can I get better results?</h3>

		<p>
			When choosing ingredients to input, it's best to use the ones that
			come first in the product's ingredients list because those
			typically make up the largest portion of the recipe. The best
			nutrients to choose are the ones with the largest values. For
			instance, Ingradient is more likely to give more meaningful results
			when it knows about protein and fat content rather than cholesterol
			and sodium content.
		</p>

		<h3>How does it work?</h3>

		<p>
			The application constructs a <a
			href="https://en.wikipedia.org/wiki/System_of_linear_equations">system
			of equations</a> where each variable is the mass of one of the
			ingredients. It solves for these masses using <a
			href="https://en.wikipedia.org/wiki/Gaussian_elimination">Gaussian
			elimination</a>.
		</p>

		<h3>Is there any tracking involved?</h3>

		<p>
			Ingradient logs your visit so <a href="http://mikepennisi.com">the
			author</a> knows how much it's being used. No information that you
			input into the application leaves your computer. Those who are
			comfortable reading software source code can verify this themselves
			by reviewing <a
			href="https://gitlab.com/jugglinmike/ingradient">the source
			code</a>.
		</p>

		<h3>What make this free and open source software?</h3>

		<p>
			From <a href="https://gnu.org">gnu.org</a>:
		</p>

		<blockquote>
			"Free software" means software that respects users' freedom and
			community. Roughly, it means that the users have the freedom to
			run, copy, distribute, study, change and improve the software.
			Thus, "free software" is a matter of liberty, not price. To
			understand the concept, you should think of "free" as in "free
			speech," not as in "free beer". We sometimes call it "libre
			software," borrowing the French or Spanish word for "free" as
			in freedom, to show we do not mean the software is gratis.
		</blockquote>

		<h3>Where did you get this idea?</h3>

		<p>
			In 2010, <a href="https://kenjilopezalt.com">J. Kenji López-Alt</a>
			${' '}wrote an article titled <a
			href="https://aht.seriouseats.com/2010/07/the-burger-lab-how-to-make-an-in-n-out-double-double-animal-style.html">The
			Ins-n-Outs of an In-N-Out Double-Double, Animal-Style</a>. In it,
			he used <a
			href="https://en.wikipedia.org/wiki/Stoichiometry">stoichiometry</a>
			${' '}to reverse-engineer the contents of a sandwich dressing
			based on the ingredients and nutrition data provided by the
			restaurant that sold it.
		</p>

		<p>
			This always seemed like a useful application of the chemistry
			process${emDash}one that could generalize to all sorts of other
			cooking experiments. It's just so labor-intensive! I thought I
			could build an application that would automate the process. That
			thought sat in the back of my mind for almost 10 years. I finally
			did something about it in the spring of 2020 when like many people,
			I was spending too much time indoors.
		</p>
	`;
};

export default ({isActive, onClose, showingTutorial, onShowTutorial}) => {
	const localize = useLocale();

	return html`
		<${Modal}
			title="${localize('HELP')}"
			isActive=${isActive}
			onClose=${onClose}>
			<${Help}
				showingTutorial=${showingTutorial}
				onShowTutorial=${onShowTutorial} />
		</>
	`;
};
