import { useState } from 'preact/hooks';

const getInitialValue = () => {
	try {
		return JSON.parse(localStorage.showingTutorial);
	// Work around bug in JSHint
	// https://github.com/jshint/jshint/issues/3480
	// jshint -W137
	} catch ({}) {
	// jshint +W137
		return true;
	}
};

export default () => {
	const [showingTutorial, setShowingTutorial] = useState(getInitialValue);

	return [showingTutorial, (value) => {
		localStorage.showingTutorial = value;
		setShowingTutorial(value);
	}];
};
