import html from './html';
import { useLocale } from './locale';

import './footer.css';

export default ({onShowLicenses}) => {
	const localize = useLocale();

	return html`
		<footer>
			<p>
				${localize('DATA_PROVIDED_BY')} <a href="https://www.ars.usda.gov/">the USDA Agricultural Research Service</a>
			</p>

			<p>
				${localize('INGRADIENT_FREE_SOFTWARE_SHORT')}
				<button onClick=${() => onShowLicenses(true)}>
					${localize('VIEW LICENSES')}
				</button>
			</p>
		</footer>
	`;
};
