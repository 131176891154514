import html from './html';
import { useState } from 'preact/hooks';
import { useLocale } from './locale';

import './equations.css';

// TODO: Use the same set of identifies for the data and for localization so
// that this conversion is not necessary.
const dataToI18n = {
	calorie: 'CALORIES',
	carbohydrate: 'TOTAL_CARBOHYDRATE',
	cholesterol: 'CHOLESTEROL',
	fiber: 'FIBER',
	protein: 'PROTEIN',
	saturatedFat: 'SATURATED_FAT',
	sodium: 'SODIUM',
	sugar: 'TOTAL_SUGARS',
	totalFat: 'TOTAL_FAT',
	transFat: 'TRANS_FAT'
};

export default ({ingredients, measures}) => {
	const localize = useLocale();
	const [isVisible, setIsVisible] = useState(false);
	let content = '';

	if (isVisible) {
		const terms = ingredients.map(({food}, index) => {
			return html`
				<tr>
					<td class="variable-name">${String.fromCharCode(97 + index)}</td>
					<td>grams of ${food.name}</td>
				</tr>
			`;
		});
		const equations = Object.entries(measures)
			.filter(([name, measure]) => measure)
			.map(([name, measure]) => {
				const left = ingredients.map(({food}, index) => {
					return (food[name] / 100).toFixed(2) + String.fromCharCode(97 + index);
				});
				return html`
					<tr>
						<td class="equation">${left.join(' + ')} = ${measure}</td>
						<td>${localize(dataToI18n[name])}</td>
					</tr>
				`;
			});

		content = html`
			<p>${localize('EQUATIONS_DESCRIPTION')}</p>

			<table class="equations">
				<thead>
					<tr>
						<td>equation</td>
						<td>derived from</td>
					</tr>
				</thead>
				<tbody>${equations}</tbody>
			</table>

			<table class="terms">
				<thead>
					<tr>
						<td>variable</td>
						<td>represents</td>
					</tr>
				</thead>
				<tbody>${terms}</tbody>
			</table>
		`;
	}

	return html`
		<div class="equations-container">
			<button class="toggle" onClick=${() => setIsVisible(!isVisible)}>
				${localize(isVisible ? 'EQUATIONS_HIDE' : 'EQUATIONS_SHOW')}
			</button>

			${content}
		</div>
	`;
};
