/**
 * This component is intended to conform with the "listbox" and "combobox"
 * patterns in the ARIA Authoring Practices guidelines
 *
 * https://www.w3.org/TR/wai-aria-practices-1.1/
 */
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import html from './html';
import { useLocale } from './locale';
import TutorialInstruction from './tutorial-instruction';
import keyCodes from './key-codes';

import './search.css';

let count = 0;

export default function Search({
		db, isLoaded, selected, onSelect, onDeselect, onCloseTutorial
	}) {
	const [results, setResults] = useState([]);
	const [focusedId, setFocusedId] = useState(null);
	const [domId] = useState(() => count += 1);
	const [terms, setTerms] = useState('');
	const latestQueryId = useRef(0);
	const localize = useLocale();
	const placeholder = localize(
		isLoaded ? 'ENTER_FOOD' : 'LOADING_FOOD_DATABASE'
	);
	const handleSelect = useCallback(
		(food) => {
			setTerms('');
			setResults([]);
			setFocusedId(null);
			onSelect(food);
		},
		[setTerms, setResults, setFocusedId, onSelect]
	);
	const handleDeselect = useCallback(
		(food) => {
			setTerms('');
			setResults([]);
			setFocusedId(null);
			onDeselect(food);
		},
		[setTerms, setResults, setFocusedId, onDeselect]
	);

	const onInput = useCallback(
		(event) => {
			const newTerms = event.target.value;
			setTerms(newTerms);

			if (newTerms.length < 2) {
				setResults([]);
				return;
			}

			// Earch results may arrive out of order; ignore "stale" results
			// from previous queryies.
			const queryId = ++latestQueryId.current;
			db.find(newTerms).then((newResults) => {
				if (latestQueryId.current !== queryId) {
					return;
				}
				setResults(newResults);
			});
		},
		[setResults, setTerms]
	);
	useEffect(() => {
		const clear = ({target}) => {
			if (target.closest('[role=combobox]')) {
				return;
			}
			setResults([]);
		};
		document.addEventListener('click', clear);
		return () => document.removeEventListener('click', clear);
	}, [setResults]);
	const resultItems = results.map((result) => {
		const isSelected = selected.some(({id}) => id === result.id);
		const isFocused = focusedId === result.id;
		return html`
			<${ResultItem}
				id=${'result-item-' + domId + '-' + result.id}
				data-id=${result.id}
				result=${result}
				selected=${isSelected}
				focused=${isFocused}
				onSelect=${handleSelect}
				onDeselect=${handleDeselect} />
		`;
	});
	const onKeypress = useCallback(
		(event) => {
			if (event.keyCode === keyCodes.escape) {
				setTerms('');
				setResults([]);
				return;
			}
			if (!results.length) {
				return;
			}

			const focusedIndex = results.findIndex(({id}) => id === focusedId);

			if (event.keyCode === keyCodes.space) {
				const focused = results[focusedIndex];

				// Space pressed prior to moving focus into combobox
				if (!focused) {
					return;
				}

				const handler = selected.some(({id}) => id === focusedId) ?
					handleDeselect : handleSelect;
				handler(focused);
				event.preventDefault();
				return;
			}
			let newFocusedIndex;
			if (event.keyCode === keyCodes.home) {
				newFocusedIndex = 0;
			} else if (event.keyCode === keyCodes.end) {
				newFocusedIndex = results.length - 1;
			} else if ([keyCodes.up, keyCodes.down].includes(event.keyCode)) {
				const delta = event.keyCode === keyCodes.down ? 1 : -1;
				newFocusedIndex = (focusedIndex + delta) % results.length;
				if (newFocusedIndex < 0) {
					newFocusedIndex = results.length - 1;
				}
			} else {
				return;
			}

			event.preventDefault();
			setFocusedId(results[newFocusedIndex].id);
		},
		[results, setResults, setTerms, selected, handleDeselect, handleSelect, focusedId, setFocusedId]
	);

	const activeDescendant = focusedId ?
		`result-item-${domId}-${focusedId}` : null;

	return html`
		<div
			class="search"
			role="combobox"
			aria-owns=${domId + '-listbox'}
			onKeydown=${onKeypress}
			aria-haspopup="listbox"
		>
			<input
				class="tutorial-input tutorial-step-2"
				type="text"
				disabled=${!isLoaded}
				placeholder=${placeholder}
				onInput=${onInput}
				onFocus=${onInput}
				value=${terms} />

			<ul
				id=${domId + '-listbox'}
				role="listbox"
				aria-multiselectable="true"
				aria-activedescendant=${activeDescendant}
				class=${results.length ? 'has-results' : ''}>${resultItems}</ul>

			<${TutorialInstruction} step="2" onClose=${onCloseTutorial} />
		</div>
	`;
}

const ResultItem = ({id, result, selected, focused, onSelect, onDeselect}) => {
	const onClick = useCallback(
		(event) => {
			const {target} = event;
			const handler = target.getAttribute('aria-selected') === 'true' ?
				onDeselect : onSelect;
			handler(result);
		},
		[onDeselect, onSelect, result]
	);
	return html`<li
		id=${id}
		role="option"
		class=${focused ? 'focused' : ''}
		onClick=${onClick}
		aria-selected=${selected}>
		${result.name}
	</li>`;
};
