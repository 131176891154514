// jshint node: true
'use strict';

function UnderConstrainedError() {}
UnderConstrainedError.prototype = Object.create(TypeError.prototype);
UnderConstrainedError.prototype.code = 'UNDERCONSTRAINED';

function OverConstrainedError() {}
OverConstrainedError.prototype = Object.create(TypeError.prototype);
OverConstrainedError.prototype.code = 'OVERCONSTRAINED';

function copy(matrix) {
	return matrix.map(function(row) {
		return row.slice();
	});
}

function pass(equations) {
	var matrix = copy(equations);
	var mixed = [];
	var column, row, source, dest, idx, jdx, coefficient, nonZeros;

	for (column = 0; column < matrix[0].length - 1; column += 1) {
		for (row = 0; row < matrix.length; row += 1) {
			if (mixed.indexOf(row) > -1) {
				continue;
			}

			// "Mix" the source into every other row--nullifying the factor at
			// index `row`.
			if (matrix[row][column]) {
				mixed.push(row);

				source = matrix[row];

				for (idx = 0; idx < matrix.length; idx += 1) {
					if (idx === row) {
						continue;
					}
					dest = matrix[idx];
					coefficient = -1 * dest[column] / source[column];

					nonZeros = 0;

					for (jdx = 0; jdx < dest.length; jdx += 1) {
						dest[jdx] += coefficient * source[jdx];

						nonZeros += dest[jdx] !== 0;
					}

					if (nonZeros === 1 && dest[jdx - 1]) {
						throw new OverConstrainedError();
					}
				}

				break;
			}
		}

		if (row === matrix.length) {
			throw new UnderConstrainedError();
		}
	}

	return matrix;
}

function normalize(accumulator, row) {
	for (var idx = 0; idx < row.length; ++idx) {
		if (row[idx] !== 0) {
			accumulator[idx] = row[row.length - 1] / row[idx];
			break;
		}
	}
	return accumulator;
}

module.exports = function (equations) {
	return pass(equations)
		.reduce(normalize, [])
		.filter(function(value) { return typeof value === 'number'; });
};
